function insertText(textarea, text) {
    var _a, _b, _c;
    const before = textarea.value.slice(0, (_a = textarea.selectionStart) !== null && _a !== void 0 ? _a : undefined);
    const after = textarea.value.slice((_b = textarea.selectionEnd) !== null && _b !== void 0 ? _b : undefined);
    let canInsertText = true;
    textarea.contentEditable = 'true';
    try {
        canInsertText = document.execCommand('insertText', false, text);
    }
    catch (error) {
        canInsertText = false;
    }
    textarea.contentEditable = 'false';
    if (canInsertText && !textarea.value.slice(0, (_c = textarea.selectionStart) !== null && _c !== void 0 ? _c : undefined).endsWith(text)) {
        canInsertText = false;
    }
    if (!canInsertText) {
        try {
            document.execCommand('ms-beginUndoUnit');
        }
        catch (e) {
        }
        textarea.value = before + text + after;
        try {
            document.execCommand('ms-endUndoUnit');
        }
        catch (e) {
        }
        textarea.dispatchEvent(new CustomEvent('change', { bubbles: true, cancelable: true }));
    }
}

const skipFormattingMap = new WeakMap();
function setSkipFormattingFlag(event) {
    const { currentTarget: el } = event;
    const isSkipFormattingKeys = event.code === 'KeyV' && (event.ctrlKey || event.metaKey) && event.shiftKey;
    if (isSkipFormattingKeys || (isSkipFormattingKeys && event.altKey)) {
        skipFormattingMap.set(el, true);
    }
}
function unsetSkipFormattedFlag(event) {
    const { currentTarget: el } = event;
    skipFormattingMap.delete(el);
}
function shouldSkipFormatting(el) {
    var _a;
    const shouldSkipFormattingState = (_a = skipFormattingMap.get(el)) !== null && _a !== void 0 ? _a : false;
    return shouldSkipFormattingState;
}
function installAround(el, ...installCallbacks) {
    el.addEventListener('keydown', setSkipFormattingFlag);
    for (const installCallback of installCallbacks) {
        installCallback(el);
    }
    el.addEventListener('paste', unsetSkipFormattedFlag);
}
function uninstall$5(el) {
    el.removeEventListener('keydown', setSkipFormattingFlag);
    el.removeEventListener('paste', unsetSkipFormattedFlag);
}

function install$4(el) {
    el.addEventListener('paste', onPaste$4);
}
function uninstall$4(el) {
    el.removeEventListener('paste', onPaste$4);
}
function onPaste$4(event) {
    const transfer = event.clipboardData;
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    if (!transfer || !hasHTML(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    let plaintext = transfer.getData('text/plain');
    const textHTML = transfer.getData('text/html');
    const textHTMLClean = textHTML.replace(/\u00A0/g, ' ').replace(/\uC2A0/g, ' ');
    if (!textHTML)
        return;
    plaintext = plaintext.trim();
    if (!plaintext)
        return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(textHTMLClean, 'text/html');
    const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_ELEMENT, node => node.parentNode && isLink(node.parentNode) ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT);
    const markdown = convertToMarkdown(plaintext, walker);
    if (markdown === plaintext)
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, markdown);
}
function convertToMarkdown(plaintext, walker) {
    var _a;
    let currentNode = walker.firstChild();
    let markdown = plaintext;
    let markdownIgnoreBeforeIndex = 0;
    let index = 0;
    const NODE_LIMIT = 10000;
    while (currentNode && index < NODE_LIMIT) {
        index++;
        const text = isLink(currentNode)
            ? (currentNode.textContent || '').replace(/[\t\n\r ]+/g, ' ')
            : ((_a = currentNode.firstChild) === null || _a === void 0 ? void 0 : _a.wholeText) || '';
        if (isEmptyString(text)) {
            currentNode = walker.nextNode();
            continue;
        }
        const markdownFoundIndex = markdown.indexOf(text, markdownIgnoreBeforeIndex);
        if (markdownFoundIndex >= 0) {
            if (isLink(currentNode)) {
                const markdownLink = linkify$2(currentNode, text);
                markdown =
                    markdown.slice(0, markdownFoundIndex) + markdownLink + markdown.slice(markdownFoundIndex + text.length);
                markdownIgnoreBeforeIndex = markdownFoundIndex + markdownLink.length;
            }
            else {
                markdownIgnoreBeforeIndex = markdownFoundIndex + text.length;
            }
        }
        currentNode = walker.nextNode();
    }
    return index === NODE_LIMIT ? plaintext : markdown;
}
function isEmptyString(text) {
    return !text || (text === null || text === void 0 ? void 0 : text.trim().length) === 0;
}
function isLink(node) {
    var _a;
    return ((_a = node.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'a' && node.hasAttribute('href');
}
function hasHTML(transfer) {
    return transfer.types.includes('text/html');
}
function linkify$2(element, label) {
    const url = element.href || '';
    let markdown = '';
    if (isUserMention(element)) {
        markdown = label;
    }
    else if (isSpecialLink(element) || areEqualLinks(url, label)) {
        markdown = url;
    }
    else {
        markdown = `[${label}](${url})`;
    }
    return markdown;
}
function isSpecialLink(link) {
    return (link.className.indexOf('commit-link') >= 0 ||
        (!!link.getAttribute('data-hovercard-type') && link.getAttribute('data-hovercard-type') !== 'user'));
}
function areEqualLinks(link1, link2) {
    link1 = link1.slice(-1) === '/' ? link1.slice(0, -1) : link1;
    link2 = link2.slice(-1) === '/' ? link2.slice(0, -1) : link2;
    return link1.toLowerCase() === link2.toLowerCase();
}
function isUserMention(link) {
    var _a;
    return ((_a = link.textContent) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) === '@' && link.getAttribute('data-hovercard-type') === 'user';
}

function install$3(el) {
    el.addEventListener('dragover', onDragover$1);
    el.addEventListener('drop', onDrop$1);
    el.addEventListener('paste', onPaste$3);
}
function uninstall$3(el) {
    el.removeEventListener('dragover', onDragover$1);
    el.removeEventListener('drop', onDrop$1);
    el.removeEventListener('paste', onPaste$3);
}
function onDrop$1(event) {
    const transfer = event.dataTransfer;
    if (!transfer)
        return;
    if (hasFile$1(transfer))
        return;
    if (!hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify$1).join(''));
}
function onDragover$1(event) {
    const transfer = event.dataTransfer;
    if (transfer)
        transfer.dropEffect = 'link';
}
function onPaste$3(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    const transfer = event.clipboardData;
    if (!transfer || !hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify$1).join(''));
}
function linkify$1(link) {
    return isImageLink(link) ? `\n![](${link})\n` : link;
}
function hasFile$1(transfer) {
    return Array.from(transfer.types).indexOf('Files') >= 0;
}
function hasLink(transfer) {
    return Array.from(transfer.types).indexOf('text/uri-list') >= 0;
}
function extractLinks(transfer) {
    return (transfer.getData('text/uri-list') || '').split('\r\n');
}
const IMAGE_RE = /\.(gif|png|jpe?g)$/i;
function isImageLink(url) {
    return IMAGE_RE.test(url);
}

function install$2(el) {
    el.addEventListener('paste', onPaste$2);
}
function uninstall$2(el) {
    el.removeEventListener('paste', onPaste$2);
}
function onPaste$2(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    const transfer = event.clipboardData;
    if (!transfer || !hasPlainText(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    const text = transfer.getData('text/plain');
    if (!text)
        return;
    if (!isURL(text))
        return;
    if (isWithinLink(field))
        return;
    const selectedText = field.value.substring(field.selectionStart, field.selectionEnd);
    if (!selectedText.length)
        return;
    if (isURL(selectedText.trim()))
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, linkify(selectedText, text));
}
function hasPlainText(transfer) {
    return Array.from(transfer.types).includes('text/plain');
}
function isWithinLink(textarea) {
    const selectionStart = textarea.selectionStart || 0;
    if (selectionStart > 1) {
        const previousChars = textarea.value.substring(selectionStart - 2, selectionStart);
        return previousChars === '](';
    }
    else {
        return false;
    }
}
function linkify(selectedText, text) {
    return `[${selectedText}](${text})`;
}
function isURL(url) {
    return /^https?:\/\//i.test(url);
}

function install$1(el) {
    el.addEventListener('dragover', onDragover);
    el.addEventListener('drop', onDrop);
    el.addEventListener('paste', onPaste$1);
}
function uninstall$1(el) {
    el.removeEventListener('dragover', onDragover);
    el.removeEventListener('drop', onDrop);
    el.removeEventListener('paste', onPaste$1);
}
function onDrop(event) {
    const transfer = event.dataTransfer;
    if (!transfer)
        return;
    if (hasFile(transfer))
        return;
    const textToPaste = generateText(transfer);
    if (!textToPaste)
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (field instanceof HTMLTextAreaElement) {
        insertText(field, textToPaste);
    }
}
function onDragover(event) {
    const transfer = event.dataTransfer;
    if (transfer)
        transfer.dropEffect = 'copy';
}
function onPaste$1(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    if (!event.clipboardData)
        return;
    const textToPaste = generateText(event.clipboardData);
    if (!textToPaste)
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (field instanceof HTMLTextAreaElement) {
        insertText(field, textToPaste);
    }
}
function hasFile(transfer) {
    return Array.from(transfer.types).indexOf('Files') >= 0;
}
function columnText(column) {
    const noBreakSpace = '\u00A0';
    const text = (column.textContent || '').trim().replace(/\|/g, '\\|').replace(/\n/g, ' ');
    return text || noBreakSpace;
}
function tableHeaders(row) {
    return Array.from(row.querySelectorAll('td, th')).map(columnText);
}
function tableMarkdown(node) {
    const rows = Array.from(node.querySelectorAll('tr'));
    const firstRow = rows.shift();
    if (!firstRow)
        return '';
    const headers = tableHeaders(firstRow);
    const spacers = headers.map(() => '--');
    const header = `${headers.join(' | ')}\n${spacers.join(' | ')}\n`;
    const body = rows
        .map(row => {
        return Array.from(row.querySelectorAll('td')).map(columnText).join(' | ');
    })
        .join('\n');
    return `\n${header}${body}\n\n`;
}
function generateText(transfer) {
    if (Array.from(transfer.types).indexOf('text/html') === -1)
        return;
    const html = transfer.getData('text/html');
    if (!/<table/i.test(html))
        return;
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(html, 'text/html');
    let table = parsedDocument.querySelector('table');
    table = !table || table.closest('[data-paste-markdown-skip]') ? null : table;
    if (!table)
        return;
    const formattedTable = tableMarkdown(table);
    return html.replace(/<meta.*?>/, '').replace(/<table[.\S\s]*<\/table>/, `\n${formattedTable}`);
}

function install(el) {
    el.addEventListener('paste', onPaste);
}
function uninstall(el) {
    el.removeEventListener('paste', onPaste);
}
function onPaste(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    const transfer = event.clipboardData;
    if (!transfer || !hasMarkdown(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    const text = transfer.getData('text/x-gfm');
    if (!text)
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, text);
}
function hasMarkdown(transfer) {
    return Array.from(transfer.types).indexOf('text/x-gfm') >= 0;
}

function subscribe(el) {
    installAround(el, install$1, install$3, install$2, install, install$4);
    return {
        unsubscribe: () => {
            uninstall$5(el);
            uninstall$1(el);
            uninstall$4(el);
            uninstall$3(el);
            uninstall$2(el);
            uninstall(el);
        }
    };
}

export { install$4 as installHTML, install$3 as installImageLink, install$2 as installLink, install$1 as installTable, install as installText, subscribe, uninstall$4 as uninstallHTML, uninstall$3 as uninstallImageLink, uninstall$2 as uninstallLink, uninstall$1 as uninstallTable, uninstall as uninstallText };
